import React from "react";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { withRouter } from "react-router";
import CardSetupForm from "./CardSetupForm";

let stripePromise =
  window.location.pathname.split("/")[2] === undefined
    ? process.env.NODE_ENV === "development"
      ? loadStripe("pk_test_cAXwtQDWd9EnLQ1dYFDCety800ZWdqhQOz")
      : loadStripe("pk_live_krEjGPuE0C0FA1kFgMsP5cJU00gym7y6of")
    : fetch(
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/getHID"
          : "https://apiv1.reserv4me.com/getHID",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            hotelname: window.location.pathname.split("/")[2],
          }),
        }
      )
        .then((data) => data.json())
        .then((result) => {
          ////console.log("we will work",window.location.pathname.split("/")[2])
          //alert(window.sessionStorage.getItem("hotelname"))
          if (process.env.NODE_ENV === "development") {
            return loadStripe("pk_test_cAXwtQDWd9EnLQ1dYFDCety800ZWdqhQOz", {
              stripeAccount: result.connectedAccID,
            });
          } else {
            return loadStripe("pk_live_krEjGPuE0C0FA1kFgMsP5cJU00gym7y6of", {
              stripeAccount: result.connectedAccID,
            });
          }
        });

class CheckoutLaterStripe extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
      //stripePromise2:loadStripe('pk_test_cAXwtQDWd9EnLQ1dYFDCety800ZWdqhQOz')
      //connectedAccID:'',
      //loaded:false
    };
  }

  render() {
    //console.log("before", this.props);

    return (
      <>
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ stripe, elements }) => {
              /*console.log(
                "stripe",
                stripePromise.then((data) => {
                  //console.log(data);
                }),
                stripePromise.Promise
              );*/
              return (
                <CardSetupForm
                  stripe={stripe}
                  elements={elements}
                  paymentroute={this.props.paymentroute}
                  fname={this.props.fname}
                  lname={this.props.lname}
                  phnum={this.props.phnum}
                  email={this.props.email}
                  loading={this.props.loading}
                  requests={this.props.requests}
                  changekey={this.props.changekey}
                  Unauthorized={this.props.Unauthorized}
                  due_date={this.props.due_date}
                />
              );
            }}
          </ElementsConsumer>
        </Elements>
      </>
    );
    //}

    // }
  }
}

export default CheckoutLaterStripe;
