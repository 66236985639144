import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import FormBuilder from "./ClientConfirmForm";
import RoomCarousel from "../Havailability/RoomCarousel";
import PaymenttypeFormafter from "../Reservation/Paymentformafterfail";
import Mainfooter from "../Footer/Mainfooter";
import moment from "moment";
import Hotelbanner from "../Hotelbanner";

class SucessPayment extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleSelected = this.handleSelected.bind(this);

    this.state = {
      key: 1,
      loading: false,
      roomobject: "",
      datagathered: false,
      reservationprops: {
        hotelname: "",
        CIdate: "",
        COdate: "",
        ADg: "",
        CHg: "",
        roomid: "",
        tripid: "",
        paymentId: "",
        token: "",
        payerId: "",
      },
      /*fname: '',
            lname: '',
            phnum: '',
            email: '',
            requests:'', */
      clfname: "",
      cllname: "",
      clphone: "",
      clemail: "",
      cladditionalreq: "",
      isreserved: false,
      notfound: false,
      paymentfailed: false,
      radiobtnselected: "Ppmethod", //controls which payment method was selected and which buttons and form should be shown
      resid: 0,

      due_date: "",
      allow_later_pay: false,
    };
  }
  handleSelected(key) {
    if (key === this.state.key) {
      this.setState({ key: 0 });
    } else {
      this.setState({ key });
    }
  }
  finishpayment = () => {
    ////console.log(window.sessionStorage.getItem('Ppal')!==null,window.sessionStorage.getItem('strLaterUsed') !== null , window.sessionStorage.getItem('strLaterUsed')==='true')

    this.setState((prevState) => ({ loading: !prevState.loading }));
    if (
      window.sessionStorage.getItem("tokenStr") !== null &&
      window.sessionStorage.getItem("StrpUse") === "true"
    ) {
      fetch(
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/success/checkoutstipe"
          : "https://apiv1.reserv4me.com/success/checkoutstipe",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: window.sessionStorage.getItem("token"),
          },
          body: JSON.stringify({
            hotelname: this.props.location.search
              .split("?")[1]
              .split("&")[0]
              .split("=")[1],
            CIdate: this.props.location.search
              .split("?")[1]
              .split("&")[1]
              .split("=")[1],
            COdate: this.props.location.search
              .split("?")[1]
              .split("&")[2]
              .split("=")[1],
            ADg: this.props.location.search
              .split("?")[1]
              .split("&")[3]
              .split("=")[1],
            CHg: this.props.location.search
              .split("?")[1]
              .split("&")[4]
              .split("=")[1],
            roomid: this.props.location.search
              .split("?")[1]
              .split("&")[5]
              .split("=")[1],
            tripid: window.sessionStorage.getItem("trid"),
            paymentId: window.sessionStorage.getItem("tokenStr"),
            fname: this.state.roomobject.tripinfo.clfname,
            lname: this.state.roomobject.tripinfo.cllname,
            phnum: this.state.roomobject.tripinfo.clphone,
            email: this.state.roomobject.tripinfo.clemail,
            requests: this.state.roomobject.tripinfo.cladditionalreq,
            stripeenable: true,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          // //console.log("after click",data=== 'done')
          if (data.success === true) {
            //console.log("we did it");
            this.setState((prevState) => ({
              loading: !prevState.loading,
              isreserved: true,
              paymentfailed: false,
              key: 1,
              resid: data.resid,
            }));
            window.scrollTo(0, 0);
            window.sessionStorage.removeItem("trid");
            window.sessionStorage.removeItem("tokenStr");
            window.sessionStorage.removeItem("token");
            window.sessionStorage.removeItem("StrpUse");
            window.sessionStorage.setItem("RoomBooked", true);
          } else {
            this.setState((prevState) => ({
              loading: !prevState.loading,
              isreserved: false,
              paymentfailed: true,
              key: 3,
            }));
            window.scrollTo(0, 0);
          }

          if (data === "Unauthorized") {
            //console.log("unauthorized");
            this.setState(
              (prevState) => ({ notfound: !prevState.notfound }),
              () => {
                // this.onSessionTimedout();
              }
            );
          }
        });
    } else if (window.sessionStorage.getItem("Ppal") !== null) {
      fetch(
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/sucess"
          : "https://apiv1.reserv4me.com/sucess",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: window.sessionStorage.getItem("token"),
          },
          body: JSON.stringify({
            hotelname: this.props.location.search
              .split("?")[1]
              .split("&")[0]
              .split("=")[1],
            CIdate: this.props.location.search
              .split("?")[1]
              .split("&")[1]
              .split("=")[1],
            COdate: this.props.location.search
              .split("?")[1]
              .split("&")[2]
              .split("=")[1],
            ADg: this.props.location.search
              .split("?")[1]
              .split("&")[3]
              .split("=")[1],
            CHg: this.props.location.search
              .split("?")[1]
              .split("&")[4]
              .split("=")[1],
            roomid: this.props.location.search
              .split("?")[1]
              .split("&")[5]
              .split("=")[1],
            tripid: window.sessionStorage.getItem("trid"),
            paymentId: this.props.location.search
              .split("?")[1]
              .split("&")[7]
              .split("=")[1],
            token: this.props.location.search
              .split("?")[1]
              .split("&")[8]
              .split("=")[1],
            payerId: this.props.location.search
              .split("?")[1]
              .split("&")[9]
              .split("=")[1],
            fname: this.state.roomobject.tripinfo.clfname,
            lname: this.state.roomobject.tripinfo.cllname,
            phnum: this.state.roomobject.tripinfo.clphone,
            email: this.state.roomobject.tripinfo.clemail,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data === "done") {
            this.setState((prevState) => ({
              loading: !prevState.loading,
              isreserved: true,
            }));
          }
        });
    } else if (
      window.sessionStorage.getItem("strLaterUsed") !== null &&
      window.sessionStorage.getItem("strLaterUsed") === "true"
    ) {
      ////console.log("heck yeah we are making res for later")

      fetch(
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/success/checkoutpaylaterstripe"
          : "https://apiv1.reserv4me.com/success/checkoutpaylaterstripe",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: window.sessionStorage.getItem("token"),
          },
          body: JSON.stringify({
            hotelname: this.props.location.search
              .split("?")[1]
              .split("&")[0]
              .split("=")[1],
            CIdate: this.props.location.search
              .split("?")[1]
              .split("&")[1]
              .split("=")[1],
            COdate: this.props.location.search
              .split("?")[1]
              .split("&")[2]
              .split("=")[1],
            ADg: this.props.location.search
              .split("?")[1]
              .split("&")[3]
              .split("=")[1],
            CHg: this.props.location.search
              .split("?")[1]
              .split("&")[4]
              .split("=")[1],
            roomid: this.props.location.search
              .split("?")[1]
              .split("&")[5]
              .split("=")[1],
            tripid: window.sessionStorage.getItem("trid"),
            fname: this.state.roomobject.tripinfo.clfname,
            lname: this.state.roomobject.tripinfo.cllname,
            phnum: this.state.roomobject.tripinfo.clphone,
            email: this.state.roomobject.tripinfo.clemail,
            requests: this.state.roomobject.tripinfo.cladditionalreq,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          // //console.log("after click later res",data)
          if (data.success === true) {
            // //console.log("we did it")
            this.setState((prevState) => ({
              loading: !prevState.loading,
              isreserved: true,
              paymentfailed: false,
              key: 1,
              resid: data.resid,
            }));
            window.scrollTo(0, 0);
            window.sessionStorage.removeItem("trid");
            window.sessionStorage.removeItem("tokenStr");
            window.sessionStorage.removeItem("token");
            window.sessionStorage.removeItem("StrpUse");
            window.sessionStorage.setItem("RoomBooked", true);
          }
          // } else {
          //     this.setState(prevState => ({ loading: !prevState.loading, isreserved: false, paymentfailed: true, key: 3 }))
          //     window.scrollTo(0, 0);
          // }

          if (data === "Unauthorized") {
            ////console.log("unauthorized")
            this.setState(
              (prevState) => ({ notfound: !prevState.notfound }),
              () => {
                // this.onSessionTimedout();
              }
            );
          }
        });
    }
  };

  componentDidMount() {
    if (window.sessionStorage.getItem("RoomBooked") === true) {
      var today = new Date();
      var tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      window.sessionStorage.removeItem("RoomBooked");
      window.location =
        process.env.NODE_ENV === "development"
          ? `http://localhost:3001/${this.props.match.params.Location}/${
              this.props.match.params.Hname
            }/avail?CIdate=${
              today.getMonth() + 1
            }/${today.getDate()}/${today.getFullYear()}&COdate=${
              tomorrow.getMonth() + 1
            }/${tomorrow.getDate()}/${tomorrow.getFullYear()}&GA=2&GCH=0`
          : `https://www.reserv4me.com/${this.props.match.params.Location}/${
              this.props.match.params.Hname
            }/avail?CIdate=${
              today.getMonth() + 1
            }/${today.getDate()}/${today.getFullYear()}&COdate=${
              tomorrow.getMonth() + 1
            }/${tomorrow.getDate()}/${tomorrow.getFullYear()}&GA=2&GCH=0`;
    } else {
      if (this.state.datagathered === false) {
        if (
          window.sessionStorage.getItem("tokenStr") !== null ||
          window.sessionStorage.getItem("strLaterUsed") !== null
        ) {
          fetch(
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/successinit"
              : "https://apiv1.reserv4me.com/successinit",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                Authorization: window.sessionStorage.getItem("token"),
              },
              body: JSON.stringify({
                hotelname: this.props.location.search
                  .split("?")[1]
                  .split("&")[0]
                  .split("=")[1],
                CIdate: this.props.location.search
                  .split("?")[1]
                  .split("&")[1]
                  .split("=")[1],
                COdate: this.props.location.search
                  .split("?")[1]
                  .split("&")[2]
                  .split("=")[1],
                ADg: this.props.location.search
                  .split("?")[1]
                  .split("&")[3]
                  .split("=")[1],
                CHg: this.props.location.search
                  .split("?")[1]
                  .split("&")[4]
                  .split("=")[1],
                roomid: this.props.location.search
                  .split("?")[1]
                  .split("&")[5]
                  .split("=")[1],
                tripid: window.sessionStorage.getItem("trid"),
                paymentId: window.sessionStorage.getItem("tokenStr"),
              }),
            }
          )
            .then((response) => response.json())
            .then((data) => {
              ////console.log("test data after",data)
              if (
                data !== undefined &&
                data !== "NotFound" &&
                data !== "Unauthorized"
              ) {
                let due_date = 0;
                let allow_later = false;

                if (window.sessionStorage.getItem("strLaterUsed") === "true") {
                  due_date = moment([
                    this.props.location.search
                      .split("?")[1]
                      .split("&")[1]
                      .split("=")[1]
                      .split("/")[2],
                    this.props.location.search
                      .split("?")[1]
                      .split("&")[1]
                      .split("=")[1]
                      .split("/")[0] - 1,
                    this.props.location.search
                      .split("?")[1]
                      .split("&")[1]
                      .split("=")[1]
                      .split("/")[1],
                  ]).subtract(data.roominfo.due_date_payment, "days");
                  allow_later = true;
                }

                if (data.isBooked.isbooked === "false") {
                  this.setState((prevState) => ({
                    roomobject: data,
                    datagathered: !prevState.datagathered,
                    clfname: data.tripinfo.clfname,
                    cllname: data.tripinfo.cllname,
                    clphone: data.tripinfo.clphone,
                    clemail: data.tripinfo.clemail,
                    cladditionalreq: data.tripinfo.cladditionalreq,
                    due_date: due_date,
                    allow_later_pay: allow_later,
                  }));
                } else if (data.isBooked.isbooked === "true") {
                  this.setState((prevState) => ({
                    roomobject: data,
                    datagathered: !prevState.datagathered,
                    isreserved: true,
                    clfname: data.tripinfo.clfname,
                    cllname: data.tripinfo.cllname,
                    clphone: data.tripinfo.clphone,
                    clemail: data.tripinfo.clemail,
                    cladditionalreq: data.tripinfo.cladditionalreq,
                  }));
                }
              } else {
                this.setState(
                  (prevState) => ({ notfound: !prevState.notfound }),
                  () => {
                    this.onSessionTimedout();
                  }
                );
              }
            });
        } else {
          fetch(
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000/successinit"
              : "https://apiv1.reserv4me.com/successinit",
            {
              //if paypal is being used

              method: "post",
              headers: {
                "Content-Type": "application/json",
                Authorization: window.sessionStorage.getItem("token"),
              },
              body: JSON.stringify({
                hotelname: this.props.location.search
                  .split("?")[1]
                  .split("&")[0]
                  .split("=")[1],
                CIdate: this.props.location.search
                  .split("?")[1]
                  .split("&")[1]
                  .split("=")[1],
                COdate: this.props.location.search
                  .split("?")[1]
                  .split("&")[2]
                  .split("=")[1],
                ADg: this.props.location.search
                  .split("?")[1]
                  .split("&")[3]
                  .split("=")[1],
                CHg: this.props.location.search
                  .split("?")[1]
                  .split("&")[4]
                  .split("=")[1],
                roomid: this.props.location.search
                  .split("?")[1]
                  .split("&")[5]
                  .split("=")[1],
                tripid: this.props.location.search
                  .split("?")[1]
                  .split("&")[6]
                  .split("=")[1],
                paymentId: this.props.location.search
                  .split("?")[1]
                  .split("&")[7]
                  .split("=")[1],
                token: this.props.location.search
                  .split("?")[1]
                  .split("&")[8]
                  .split("=")[1],
                payerId: this.props.location.search
                  .split("?")[1]
                  .split("&")[9]
                  .split("=")[1],
              }),
            }
          )
            .then((response) => response.json())
            .then((data) => {
              if (
                data !== undefined &&
                data !== "NotFound" &&
                data !== "Unauthorized"
              ) {
                ////console.log(data)
                if (data.isBooked.isbooked === "false") {
                  this.setState((prevState) => ({
                    roomobject: data,
                    datagathered: !prevState.datagathered,
                  }));
                } else if (data.isBooked.isbooked === "true") {
                  this.setState((prevState) => ({
                    roomobject: data,
                    datagathered: !prevState.datagathered,
                    isreserved: true,
                  }));
                }
              } else {
                this.setState(
                  (prevState) => ({ notfound: !prevState.notfound }),
                  () => {
                    this.onSessionTimedout();
                  }
                );
              }
            });
        }
      }
    }
  }
  radioselected = (e) => {
    this.setState({ radiobtnselected: e.target.value });
  };

  onSessionTimedout = () => {
    ////console.log("deleting",this.props.location.search.split('?')[1].split("&"))
    window.sessionStorage.removeItem("StrpUse");
    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("tokenStr");
    window.sessionStorage.removeItem("trid");
  };

  onclickhere = () => {
    window.location =
      process.env.NODE_ENV === "development"
        ? `http://localhost:3001/USA/${
            this.props.location.search.split("?")[1].split("&")[0].split("=")[1]
          }/avail?CIdate=${
            this.props.location.search.split("?")[1].split("&")[1].split("=")[1]
          }&COdate=${
            this.props.location.search.split("?")[1].split("&")[2].split("=")[1]
          }&GA=${
            this.props.location.search.split("?")[1].split("&")[3].split("=")[1]
          }&GCH=${
            this.props.location.search.split("?")[1].split("&")[4].split("=")[1]
          }`
        : `https://www.reserv4me.com/USA/${
            this.props.location.search.split("?")[1].split("&")[0].split("=")[1]
          }/avail?CIdate=${
            this.props.location.search.split("?")[1].split("&")[1].split("=")[1]
          }&COdate=${
            this.props.location.search.split("?")[1].split("&")[2].split("=")[1]
          }&GA=${
            this.props.location.search.split("?")[1].split("&")[3].split("=")[1]
          }&GCH=${
            this.props.location.search.split("?")[1].split("&")[4].split("=")[1]
          }`;
    return false;
  };

  render() {
    console.log(this.state);
    return (
      <>
        <div className="flex flex-col min-h-screen overflow-hidden">
          <Hotelbanner
            Hname={
              this.props.location.search
                .split("?")[1]
                .split("&")[0]
                .split("=")[1]
            }
          />
          <main className="grow">
            <div className="relative mt-20">
              {(() => {
                if (!this.state.notfound) {
                  //if notfound===true then will say that the reservation was made and advice customer to check with their hotel
                  if (this.state.datagathered === true) {
                    const { datagathered, roomobject } = this.state;
                    if (this.state.roomobject === "Unauthorized") {
                      return (
                        <h1>Something went wrong go back to here, Please!</h1>
                      );
                    } else {
                      return (
                        <div>
                          {/*Loading screen*/}
                          <div
                            hidden={this.state.loading === false ? true : false}
                            className=" fixed pt-20  inset-0 mx-auto z-10  bg-black/75  ">
                            <div className="loading"></div>
                            <div className="absolute  w-60 left-1/2 top-1/2  -translate-y-2/4 -translate-x-2/4 ">
                              <p className="text-2xl text-white -translate-y-16">
                                Please be patient
                              </p>
                            </div>
                          </div>

                          <div className="grid  grid-flow-col grid-cols-12 grid-rows-2 lg:grid-rows-1 p-6 mx-2 space-x-0 lg:space-x-4">
                            <div className=" grid shadow-3xl rounded-lg col-span-full row-span-full row-start-3 lg:col-span-6 lg:row-span-full space-y-4">
                              <div className="col-span-12 px-4 text-2xl font-light pt-4">
                                {this.state.paymentfailed === true
                                  ? "Payment failed"
                                  : this.state.isreserved === true
                                  ? `Reservation was completed succesfully. Confirmation #:${this.state.resid}`
                                  : `Congratulations!, one step closer from completing your reservation`}
                              </div>

                              <div className="col-span-12 px-4 text-2xl font-light pt-4">
                                <p>
                                  {this.state.isreserved === true
                                    ? ""
                                    : "Please verify all the info below:"}
                                </p>
                              </div>
                              <div className="col-span-12 px-4">
                                <h5>{`Unit type: ${this.state.roomobject.roominfo.roomtype}`}</h5>
                              </div>
                              <div className="col-span-4 px-4">
                                <span>From:</span>
                                <span>
                                  {this.state.roomobject.tripinfo.cidate}
                                </span>
                              </div>
                              <div className="col-span-4 px-4">
                                <span>To:</span>
                                <span>
                                  {this.state.roomobject.tripinfo.codate}
                                </span>
                              </div>
                              <div className="col-span-12">
                                <FormBuilder
                                  roomobject={this.state.roomobject}
                                  clfname={this.state.clfname}
                                  cllname={this.state.cllname}
                                  clphone={this.state.clphone}
                                  clemail={this.state.clemail}
                                  cladditionalreq={
                                    this.state.cladditionalreq
                                  }></FormBuilder>
                              </div>
                              <div className="col-span-12 px-4 text-2xl font-light">
                                <h5>Room Cost: </h5>
                              </div>
                              <div className="grid grid-cols-12 col-span-12 text-right pt-10">
                                <span className="col-start-3  col-span-5 lg:col-start-4  lg:col-span-4">
                                  {this.state.roomobject.roominfo
                                    .cleaning_fee == 0
                                    ? "Room Price :"
                                    : "Room Price & Cleaning:"}
                                </span>
                                <span className="col-start-8  col-span-4 lg:col-start-8  lg:col-span-4">{`$ ${this.state.roomobject.roominfo.totalcost}`}</span>
                              </div>
                              <div className="grid grid-cols-12 col-span-12 text-right">
                                <span className="col-start-3  col-span-5 lg:col-start-4  lg:col-span-4">{`${
                                  this.state.roomobject.roominfo.tax_except ===
                                  true
                                    ? "Service Fees:"
                                    : "Tax & fees:"
                                }`}</span>
                                <span className="col-start-8  col-span-4 lg:col-start-8  lg:col-span-4">{`$ ${this.state.roomobject.roominfo.taxcharge}`}</span>
                              </div>
                              <div className="grid grid-cols-12 col-span-12 text-right">
                                <span className="col-start-3  col-span-5 lg:col-start-4  lg:col-span-4">
                                  Total :
                                </span>
                                <span className="col-start-8  col-span-4 lg:col-start-8  lg:col-span-4">{`$ ${this.state.roomobject.roominfo.total_w_tax}`}</span>
                              </div>
                              <div className="grid grid-cols-12 col-span-12 text-right">
                                <span className="col-start-3  col-span-5 lg:col-start-4  lg:col-span-4">
                                  Down Payment due{" "}
                                  {this.state.allow_later_pay === true
                                    ? `${this.state.due_date.format(
                                        "MMM DD,YYYY"
                                      )}`
                                    : `Today`}
                                  :{" "}
                                </span>
                                <span className="col-start-8  col-span-4 lg:col-start-8  lg:col-span-4">{`$${
                                  this.state.roomobject.roominfo
                                    .typeofcancel === "percentage"
                                    ? (
                                        parseFloat(
                                          this.state.roomobject.roominfo.total_w_tax.replace(
                                            /[^0-9\.-]+/g,
                                            ""
                                          )
                                        ) *
                                        (this.state.roomobject.roominfo
                                          .dpamount /
                                          100)
                                      ).toFixed(2)
                                    : this.state.roomobject.roominfo
                                        .typeofcancel === "night"
                                    ? (
                                        parseFloat(
                                          this.state.roomobject.roominfo
                                            .roomfirstnight
                                        ) *
                                        (1 +
                                          this.state.roomobject.roominfo
                                            .taxrate /
                                            100)
                                      ).toFixed(2)
                                    : ""
                                }`}</span>
                              </div>
                              <div className="grid grid-cols-12 col-span-12 text-right">
                                <span className="col-start-3  col-span-5 lg:col-start-4  lg:col-span-4">
                                  Amount due at Check-in :
                                </span>
                                <span className="col-start-8  col-span-4 lg:col-start-8  lg:col-span-4">{`$${(
                                  parseFloat(
                                    this.state.roomobject.roominfo.total_w_tax
                                  ) -
                                  (this.state.roomobject.roominfo
                                    .typeofcancel === "percentage"
                                    ? (
                                        parseFloat(
                                          this.state.roomobject.roominfo.total_w_tax.replace(
                                            /[^0-9\.-]+/g,
                                            ""
                                          )
                                        ) *
                                        (this.state.roomobject.roominfo
                                          .dpamount /
                                          100)
                                      ).toFixed(2)
                                    : this.state.roomobject.roominfo
                                        .typeofcancel === "night"
                                    ? (
                                        parseFloat(
                                          this.state.roomobject.roominfo
                                            .roomfirstnight
                                        ) *
                                        (1 +
                                          this.state.roomobject.roominfo
                                            .taxrate /
                                            100)
                                      ).toFixed(2)
                                    : "")
                                ).toFixed(2)}`}</span>
                              </div>

                              {/** */}
                              <div
                                className={
                                  this.state.paymentfailed === true
                                    ? "hidden"
                                    : this.state.isreserved === true
                                    ? "hidden"
                                    : " col-span-12 px-4 flex justify-end p-5"
                                }>
                                <button
                                  className="font-normal  bg-blue-500 text-white p-2 rounded inline "
                                  onClick={this.finishpayment}>
                                  {"Confirm & Pay"}
                                </button>
                              </div>
                            </div>
                            <div className=" col-span-full row-span-full row-start-1 lg:col-span-6 lg:row-span-full pb-6 lg:pb-0">
                              <div
                                class="accordion accordion-flush"
                                id="accordionFlushExample">
                                <div class="accordion-item border-t-0 border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
                                  <h2
                                    class="accordion-header mb-0"
                                    id="flush-headingOne">
                                    <button
                                      class={`accordion-button
                                      relative
                                      flex
                                      items-center
                                      w-full
                                      py-4
                                      px-5
                                      text-base text-gray-800 text-left
                                      bg-white
                                      border-0
                                      rounded-none
                                      transition
                                      focus:outline-none ${
                                        this.state.key === 1 ? "" : "collapsed"
                                      }`}
                                      type="button"
                                      onClick={() => this.handleSelected(1)}>
                                      Unit Photos:
                                    </button>
                                  </h2>
                                  <div
                                    id="flush-collapseOne"
                                    class={`accordion-collapse border-0 collapse ${
                                      this.state.key === 1 ? "show" : ""
                                    }`}>
                                    <div class="accordion-body py-4 px-5">
                                      <RoomCarousel
                                        roomphoto={
                                          datagathered === false
                                            ? ""
                                            : roomobject.roominfo.photourls
                                        }
                                        roomtype={
                                          this.state.roomobject.roominfo
                                            .roomtype
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
                                  <h2
                                    class="accordion-header mb-0"
                                    id="flush-headingTwo">
                                    <button
                                      class={`accordion-button
                                      relative
                                      flex
                                      items-center
                                      w-full
                                      py-4
                                      px-5
                                      text-base text-gray-800 text-left
                                      bg-white
                                      border-0
                                      rounded-none
                                      transition
                                      focus:outline-none ${
                                        this.state.key === 2 ? "" : "collapsed"
                                      }`}
                                      type="button"
                                      onClick={() => this.handleSelected(2)}>
                                      Policies:
                                    </button>
                                  </h2>
                                  <div
                                    id="flush-collapseTwo"
                                    class={`accordion-collapse border-0 collapse ${
                                      this.state.key === 2 ? "show" : ""
                                    }`}>
                                    <div class="accordion-body py-4 px-5">
                                      <div>
                                        <ul>
                                          <li className="space-x-4">
                                            <h3>Check-in/Check-out info:</h3>
                                            <span className="pl-2 block">
                                              Check-in:{" "}
                                              {this.state.datagathered
                                                ? this.state.roomobject.roominfo
                                                    .timecheckin
                                                : ""}{" "}
                                              local time
                                            </span>
                                            <span className="pl-2 block">
                                              Check-out:{" "}
                                              {this.state.datagathered
                                                ? this.state.roomobject.roominfo
                                                    .timecheckout
                                                : ""}{" "}
                                              local time
                                            </span>
                                          </li>
                                          <li className="space-x-4">
                                            <h3>Down Payment Policy:</h3>
                                            <span
                                              className="pl-2  "
                                              hidden={
                                                this.state.allow_later_pay ===
                                                true
                                                  ? true
                                                  : false
                                              }>
                                              A down payment of{" "}
                                              {this.state.datagathered
                                                ? `${
                                                    this.state.roomobject
                                                      .roominfo.dpamount
                                                  }
                                                                            ${
                                                                              this
                                                                                .state
                                                                                .roomobject
                                                                                .roominfo
                                                                                .typeofdp ===
                                                                              "percentage"
                                                                                ? "%"
                                                                                : this
                                                                                    .state
                                                                                    .roomobject
                                                                                    .roominfo
                                                                                    .typeofcancel ===
                                                                                  "night"
                                                                                ? " night + tax and fees "
                                                                                : ""
                                                                            } `
                                                : ""}
                                              is due at time of booking
                                            </span>
                                            <span
                                              className="pl-2 "
                                              hidden={
                                                this.state.allow_later_pay ===
                                                true
                                                  ? false
                                                  : true
                                              }>
                                              A down payment of{" "}
                                              {this.state.datagathered
                                                ? `${
                                                    this.state.roomobject
                                                      .roominfo.dpamount
                                                  }
                                                                            ${
                                                                              this
                                                                                .state
                                                                                .roomobject
                                                                                .roominfo
                                                                                .typeofdp ===
                                                                              "percentage"
                                                                                ? "%"
                                                                                : this
                                                                                    .state
                                                                                    .roomobject
                                                                                    .roominfo
                                                                                    .typeofcancel ===
                                                                                  "night"
                                                                                ? " night + tax and fees "
                                                                                : ""
                                                                            } `
                                                : ""}
                                              will automatically be charged on{" "}
                                              {`${
                                                this.state.allow_later_pay ===
                                                true
                                                  ? this.state.due_date.format(
                                                      "MMM DD, YYYY"
                                                    )
                                                  : ""
                                              }`}
                                            </span>
                                          </li>
                                          <li className="MarginsinsideTab">
                                            <h3>Cancelation Policy:</h3>
                                            <span className="pl-2 block">
                                              You can cancel free of charge up
                                              to{" "}
                                              {this.state.datagathered
                                                ? `${this.state.roomobject.roominfo.freetime} ${this.state.roomobject.roominfo.timeframe} `
                                                : ""}
                                              prior to your Check-In date
                                              outside the free cancellation
                                              window a fee of{" "}
                                              {this.state.datagathered
                                                ? `${
                                                    this.state.roomobject
                                                      .roominfo.cost
                                                  }
                                                                            ${
                                                                              this
                                                                                .state
                                                                                .roomobject
                                                                                .roominfo
                                                                                .typeofcancel ===
                                                                              "percentage"
                                                                                ? "%"
                                                                                : ""
                                                                            } `
                                                : this.state.roomobject.roominfo
                                                    .typeofcancel === "night"
                                                ? " night + tax and fees "
                                                : ""}{" "}
                                              will be charged
                                            </span>
                                          </li>
                                          <li className="space-x-4">
                                            <h3>Parking Policy:</h3>
                                            <span className="pl-2 block">
                                              {this.state.datagathered
                                                ? `${roomobject.roominfo.parking_policy}  `
                                                : ""}
                                            </span>
                                          </li>
                                          <li className="space-x-4">
                                            <h3>Pet Policy:</h3>
                                            <span className="pl-2 block">
                                              {this.state.datagathered
                                                ? `${roomobject.roominfo.pet_policy}  `
                                                : ""}
                                            </span>
                                          </li>
                                          <li className="space-x-4">
                                            <h3>Additinal Policies:</h3>
                                            <span className="pl-2 block">
                                              {this.state.datagathered
                                                ? `${roomobject.roominfo.additional_pol}  `
                                                : ""}
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {this.state.paymentfailed === true ? (
                                  <div class="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
                                    <h2
                                      class="accordion-header mb-0"
                                      id="flush-headingThree">
                                      <button
                                        class={`accordion-button
      
                                        relative
                                        flex
                                        items-center
                                        w-full
                                        py-4
                                        px-5
                                        text-base text-gray-800 text-left
                                        bg-white
                                        border-0
                                        rounded-none
                                        transition
                                        focus:outline-none ${
                                          this.state.key === 3
                                            ? ""
                                            : "collapsed"
                                        }`}
                                        type="button"
                                        onClick={() => this.handleSelected(3)}>
                                        Payment:
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseThree"
                                      className={`accordion-collapse border-0 collapse ${
                                        this.state.key === 3 ? "show" : ""
                                      }`}>
                                      <div class="accordion-body py-4 px-5">
                                        <span className="text-red-500 text-xl font-semibold">
                                          Payment form is not valid, please
                                          provide another payment form
                                        </span>
                                        <PaymenttypeFormafter
                                          paymentroute={this.finishpayment} //finish payment after fail
                                          radioselected={this.radioselected}
                                          radiobtnselected={
                                            this.state.radiobtnselected
                                          }
                                          fname={this.state.valuefname}
                                          lname={this.state.valuelname}
                                          phnum={this.state.valuephnum}
                                          email={this.state.valueemail}
                                          requests={
                                            this.state.valueadditionalRequests
                                          }
                                          status={
                                            this.state.paymentfailed === true
                                              ? "failed"
                                              : ""
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  } else {
                    return (
                      <>
                        {/*Loading screen*/}
                        <div
                          hidden={this.state.loading === false ? true : false}
                          className=" fixed pt-20  inset-0 mx-auto z-10  bg-black/75  ">
                          <div className="loading"></div>
                          <div className="absolute  w-60 left-1/2 top-1/2  -translate-y-2/4 -translate-x-2/4 ">
                            <p className="text-2xl text-white -translate-y-16">
                              Please be patient
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  }
                } else {
                  return (
                    <div>
                      <center>
                        <h2>
                          It seems your session timed out... click{" "}
                          <a
                            onClick={this.onclickhere}
                            href="javascript:void(0);">
                            here
                          </a>{" "}
                          to start again
                        </h2>
                        <h2>
                          If your reservation was completed check for a
                          confirmation in your email
                        </h2>
                        <h3>
                          If you believe this message was an error... call the
                          hotel phone number
                        </h3>
                      </center>
                    </div>
                  );
                }
              })()}
            </div>
          </main>
          <Mainfooter />
        </div>
      </>
    );
  }
}
export default withRouter(SucessPayment);
