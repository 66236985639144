import HeaderTop from "../header";
import Footer from "../Footer/Mainfooter";
import React, { Component, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, Link } from "react-router-dom";

import {
  GoogleMap,
  LoadScript,
  useLoadScript,
  Marker,
  InfoWindow,
  MarkerF,
} from "@react-google-maps/api";
import mapStyles from "./mapStyles";

import pinIcon from "../../img/SVG/pin.svg";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import RoomCarousel from "../Havailability/RoomCarousel";

const today = moment();
const libraries = ["places"];

const initalState = {
  dateCI: moment(),
  dateCO: moment().add(1, "days"),
  GuestAdult: 1,
  GuestChild: 0,
};

const mapContainerStyle = {
  width: "400px",
  height: "100vh",
};

const center = {
  lat: 39.942822,
  lng: -74.075142,
};

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};

function SearchBarPredict({
  isLoaded,
  loadError,
  panTo,
  mapRef,
  onMapLoad,
  loc,
  setLocation,
  onMapClick,
  setHasSelectedLoc,
}) {
  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loadingmaps";

  return (
    <>
      <Search
        panTo={panTo}
        setLocation={setLocation}
        setHasSelectedLoc={setHasSelectedLoc}
      />
    </>
  );
}

function Search({ panTo, setLocation, setHasSelectedLoc }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: {
        lat: () => 39.942822,
        lng: () => -74.075142,
      },
      radius: 200 * 1000,
    },
  });
  return (
    <div className="w-full ">
      <div className="flex justify-center">
        <div className="w-full">
          <div className="dropdown relative">
            <input
              className="
              w-full
          dropdown-toggle
          px-6
          py-2.5
          bg-white
          font-medium
          text-xs
          leading-tight
          uppercase
          rounded
          shadow-md
           focus:shadow-lg focus:outline-none focus:ring-0
           active:shadow-lg
          transition
          duration-150
          ease-in-out
          flex
          items-center
          whitespace-nowrap
        "
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="true"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              disabled={!ready}
              placeholder={"Enter an Address"}></input>
            <ul
              className="
          dropdown-menu
          min-w-max
          absolute
          hidden
          bg-white
          text-base
          z-50
          float-left
          py-2
          list-none
          text-left
          rounded-lg
          shadow-lg
          mt-1
          hidden
          m-0
          bg-clip-padding
          border-none
        "
              aria-labelledby="dropdownMenuButton1">
              {status === "OK" &&
                data.map((element, id) => {
                  return (
                    <li key={element.description}>
                      <a
                        className="
              dropdown-item
              text-sm
              py-2
              px-4
              font-normal
              block
              w-full
              whitespace-nowrap
              bg-transparent
              text-gray-700
              hover:bg-gray-100
            "
                        key={id - element.description}
                        id={element.description}
                        onClick={async (e) => {
                          const tempvar = e.target.id;
                          setValue(tempvar, false);
                          clearSuggestions();
                          try {
                            setHasSelectedLoc(true);
                            const result = await getGeocode({
                              address: tempvar,
                            });
                            const { lat, lng } = await getLatLng(result[0]);
                            //panTo({ lat, lng });
                            setLocation(() => ({ lat: lat, lng: lng }));
                          } catch (err) {
                            //console.log(err);
                          }

                          //console.log(tempvar);
                        }}>
                        {element.description}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function MapContainer({
  onMapLoad,
  onMapClick,
  onBoundsChanged,
  mapRef,
  setBoundaries,
  places,
  availaibity_ask,
}) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC0HfUufUWG2qY86_6GvfSpW6x-PPPkCw0",
    libraries,
  });
  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loadingmaps";
  return (
    <>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={12}
        center={center}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
        onBoundsChanged={onBoundsChanged}
        onIdle={() => {
          let ne = mapRef.current.getBounds().getNorthEast();
          let sw = mapRef.current.getBounds().getSouthWest();
          setBoundaries({
            upper: { lat: ne.lat(), lng: ne.lng() },
            lower: { lat: sw.lat(), lng: sw.lng() },
          });
        }}>
        {availaibity_ask &&
          places.nearby_places.map((place, i) => {
            return places.hotelspecifics[i][0].map((c, j) => {
              if (c.isavail === true) {
                return (
                  <>
                    <MarkerF
                      key={`${i}-${j}`}
                      position={{
                        lat: place.latitude_loc,
                        lng: place.longitude_loc,
                      }}
                      // label={{
                      //   //text: `${marker.hotelwebsite}`,
                      //   className:
                      //     "bg-white rounded-lg p-1 drop-shadow-xl border-black border ",
                      // }}
                      icon={pinIcon}
                      // icon={{
                      //   path: pinIcon,
                      //   scaledSize: new window.google.maps.Size(0, 0),
                      //   origin: new window.google.maps.Point(0, 0),
                      //   anchor: new window.google.maps.Point(15, 15),
                      // }}

                      // onClick={() => {
                      //   setSelected(marker);
                      // }}
                    ></MarkerF>
                  </>
                );
              } else {
                return <></>;
              }
            });
          })}

        {/* {selected ? (
          <InfoWindow
            position={{ lat: selected.lat, lng: selected.lng }}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <>
              <h2>Bear Spotted!</h2>
              <p>Spotted {formatRelative(selected.time, new Date())}</p>
            </>
          </InfoWindow>
        ) : null}  */}
      </GoogleMap>
    </>
  );
}

function OnChangedateCI(dateCIentered, dateCI, dateCO, setCIDate, setCODate) {
  if (
    dateCIentered.diff(today, "days") >= 0 &&
    dateCO.diff(dateCIentered, "days") > 0
  ) {
    ////console.log("here")
    setCIDate(dateCIentered);
  }
  if (dateCO.diff(dateCIentered, "days") <= 0) {
    ////console.log("here1", this.state.dateCO === initalState.dateCO)
    setCIDate((prevState) =>
      prevState.add(dateCIentered.diff(prevState, "days") + 1, "days")
    );
    setCODate(() => dateCIentered.add(1, "days"));
  }
}

function OnChangedateCO(dateCOenter, dateCI, dateCO, setCIDate, setCODate) {
  if (dateCOenter > dateCO && dateCOenter > dateCI) {
    //dateCOenter.diff(this.state.dateCO,"days")>=0&&dateCOenter.diff(this.state.dateCI,"days")>0)
    setCODate(dateCOenter);
  } else {
    if (dateCOenter < dateCO && dateCOenter > today) {
      if (dateCOenter <= dateCI) {
        setCIDate((prevState) =>
          moment(
            prevState.dateCI.subtract(
              prevState.dateCI.diff(dateCOenter, "days") + 1,
              "days"
            )
          )
        );
        setCODate(dateCOenter);
      } else if (dateCOenter > dateCI) {
        setCODate(dateCOenter);
      }
    } else if (dateCOenter < dateCO && dateCOenter <= today) {
      setCIDate((prevState) =>
        dateCOenter.diff(prevState.dateCI, "days") > 0 ? today : today
      );
      setCODate(initalState.dateCO);
    }
  }
}

function GuestSelectedAd(direction, type, setGuestAdult, setGuestChild) {
  if (direction === "up") {
    if (type === "Adult") {
      setGuestAdult((GuestAdult) => GuestAdult + 1);
    } else {
      setGuestChild((GuestChild) => GuestChild + 1);
    }
  } else {
    if (type === "Adult") {
      setGuestAdult((prevState) => (prevState > 1 ? prevState - 1 : prevState));
    } else {
      setGuestChild((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }

  //this.setState({ GuestAdult: e.target.value });
}

// function GuestValueManual(e, setGuestAdult, setGuestChild) {
//   let tempvar = e.target;

//   let textreg = /^-?\d*$/;

//   if (textreg.test(tempvar.value) && tempvar.value !== "") {
//     if (tempvar.id === "Adult") {
//       setGuestAdult((prevState) => tempvar.value);
//     } else {
//       setGuestChild((prevState) => tempvar.value);
//     }
//   } else {
//     //console.log("empty");
//     return null;
//   }
// }

function onCheckAvail(
  panTo,
  loc,
  setAvailability,
  isLoaded,
  boundaries,
  hasSelectedLoc
) {
  try {
    if (hasSelectedLoc === true) {
      setAvailability(true);

      panTo({ lat: loc.lat, lng: loc.lng });
    }
  } catch (err) {
    //console.log(err);
  }
}

const Welcome_Home = () => {
  const history = useHistory();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC0HfUufUWG2qY86_6GvfSpW6x-PPPkCw0",
    libraries,
  });

  const [loc, setLocation] = React.useState({
    lat: 39.942822,
    lng: -74.075142,
  });
  const [selected, setSelected] = React.useState(null);
  const [boundaries, setBoundaries] = React.useState(null);
  const [places_avail, setPlacesAvail] = React.useState({ nearby_places: [] });
  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);
  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);
  const [hasSelectedLoc, setHasSelectedLoc] = React.useState(false);
  const onMapClick = React.useCallback((event) => {
    // setMarkers((current) => [
    //   ...current,
    //   {
    //     lat: event.latLng.lat(),
    //     lng: event.latLng.lng(),
    //     time: new Date(),
    //   },
    // ]);
    //console.log("test");
  }, []);

  const [dateCI, setCIDate] = useState(moment());
  const [dateCO, setCODate] = useState(moment().add(1, "days"));
  const [GuestAdult, setGuestAdult] = useState(1);
  const [GuestChild, setGuestChild] = useState(0);
  const [availaibity_ask, setAvailability] = useState(false);

  const onBoundsChanged = React.useCallback(
    (sa) => {
      let limits = {
        upper: {
          lat: mapRef.current.getBounds().getNorthEast().lat(),
          lng: mapRef.current.getBounds().getNorthEast().lng(),
        },
        lower: {
          lat: mapRef.current.getBounds().getSouthWest().lat(),
          lng: mapRef.current.getBounds().getSouthWest().lng(),
        },
      };
      if (limits.upper.lat !== limits.lower.lat) {
        fetch(
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/places_avail"
            : "https://apiv1.reserv4me.com/places_avail",
          {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              limits: limits,
              CIdate: dateCI,
              COdate: dateCO,
              ADg: GuestAdult,
              CHg: GuestChild,
            }),
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            setPlacesAvail(data.hotel_availinfo);
            window.sessionStorage.setItem("dateCI", dateCI);
            window.sessionStorage.setItem("dateCO", dateCO);
            window.sessionStorage.setItem("GuestAdult", GuestAdult);
            window.sessionStorage.setItem("GuestChild", GuestChild);
          });
      } else {
        return null;
      }
    },
    [dateCI, dateCO, GuestAdult, GuestChild]
  );

  const GuestValueManual = React.useCallback(
    (e, setGuestAdult, setGuestChild) => {
      let tempvar = e.target;

      let textreg = /^-?\d*$/;

      if (textreg.test(tempvar.value) && tempvar.value !== "") {
        if (tempvar.id === "Adult") {
          setGuestAdult(tempvar.value);
        } else {
          setGuestChild(tempvar.value);
        }
      } else {
        //console.log("empty");
        return null;
      }
    }
  );

  // const onSelectionReservation = React.useCallback((event) => {
  //   console.log("hi this is a test", event.target.id.split("-")[1]);
  //   console.log(dateCI, dateCO, GuestAdult, GuestChild);

  //   return history.push(
  //     `/USA/${event.target.id.split("-")[1]}/avail?CIdate=${dateCI.format(
  //       "MM/DD/YYYY"
  //     )}&COdate=${dateCO.format(
  //       "MM/DD/YYYY"
  //     )}&GA=${GuestAdult}&GCH=${GuestChild}`
  //   );
  // }, []);
  //console.log(GuestAdult, GuestChild, dateCI, dateCO);

  return (
    <>
      <div className="flex flex-col min-h-screen overflow-hidden">
        <HeaderTop />
        <div className="bg-gradient-to-r from-green-400 to-blue-500  pt-20 pb-20 px-4">
          <div
            className="container justify-center mx-auto drop-shadow-xl 
          bg-white  rounded-lg p-10 bg-opacity-75  border-blue-800
          max-w-6xl m-10 ">
            <div className="grid grid-cols-2 gap-4">
              <div
                className=" flex lg:justify-start 
                                  lg:pl-7 mb-4 col-span-2">
                <h3 className="font-light text-2xl ">
                  Select a location and dates for reservation:
                </h3>
              </div>
              <div
                className=" flex lg:justify-start 
                                   mb-4 col-span-2  w-10/12 m-auto space-x-2 ">
                <label for="location" className="font-light text-xl ">
                  Location:
                </label>
                {/* <input
                    className="pl-7 bg-white  border font-medium text-xl rounded w-full border-black"
                    //value={this.state.GuestChild}
                    id="location"
                    name="location"
                    //onChange={this.GuestValueManual}
                  /> */}
                <SearchBarPredict
                  isLoaded={isLoaded}
                  loadError={loadError}
                  panTo={panTo}
                  mapRef={mapRef}
                  onMapLoad={onMapLoad}
                  loc={loc}
                  setLocation={setLocation}
                  setHasSelectedLoc={setHasSelectedLoc}
                />
              </div>

              <div className="block md:flex space-x-2 justify-end  ">
                <h4 className="font-light text-xl w-20 md:w-40 ">From:</h4>
                <DatePicker
                  selected={dateCI}
                  selectsStart
                  startDate={dateCI}
                  endDate={dateCO}
                  onChange={(date) =>
                    OnChangedateCI(date, dateCI, dateCO, setCIDate, setCODate)
                  }
                  id="CI"
                  className=" rounded  text-center w-full md:w-40 lg:w-60 "
                  popperModifiers={{
                    flip: {
                      behavior: ["bottom"], // don't allow it to flip to be above
                    },
                    preventOverflow: {
                      enabled: true, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                    },
                    hide: {
                      enabled: false, // turn off since needs preventOverflow to be enabled
                    },
                  }}
                />
              </div>
              <div className="block md:flex space-x-2 ">
                <h4 className="font-light text-xl w-20 md:w-40  ">Until:</h4>
                <DatePicker
                  selected={dateCO}
                  selectsEnd
                  startDate={dateCI}
                  endDate={dateCO}
                  onChange={(date) =>
                    OnChangedateCO(date, dateCI, dateCO, setCIDate, setCODate)
                  }
                  id="CO"
                  className="rounded  text-center w-full md:w-40 lg:w-60"
                  popperModifiers={{
                    flip: {
                      behavior: ["bottom"], // don't allow it to flip to be above
                    },
                    preventOverflow: {
                      enabled: true, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                    },
                    hide: {
                      enabled: false, // turn off since needs preventOverflow to be enabled
                    },
                  }}
                />
              </div>
              <div className="block md:flex space-x-2 justify-end">
                <h4 className="font-light text-xl w-20 md:w-40 ">Adults:</h4>
                <div className="w-full md:w-40 lg:w-60 space-x-4  flex justify-center items-center">
                  <button
                    className="font-normal text-2xl bg-blue-500 text-white p-2 rounded w-12  "
                    onClick={() => {
                      GuestSelectedAd(
                        "down",
                        "Adult",
                        setGuestAdult,
                        setGuestChild,
                        GuestAdult,
                        GuestChild
                      );
                    }}>
                    -
                  </button>
                  <input
                    className="bg-white w-12 text-center font-medium text-xl rounded"
                    value={GuestAdult}
                    id="Adult"
                    onChange={(e) =>
                      GuestValueManual(e, setGuestAdult, setGuestChild)
                    }
                  />
                  <button
                    className="font-normal text-2xl bg-blue-500 text-white p-2 rounded w-12"
                    onClick={() => {
                      GuestSelectedAd(
                        "up",
                        "Adult",
                        setGuestAdult,
                        setGuestChild
                      );
                    }}>
                    +
                  </button>
                </div>
              </div>
              <div className="block md:flex space-x-2 ">
                <h4 className="font-light text-xl w-20 md:w-40 ">Children:</h4>
                <div className="w-full md:w-40 lg:w-60 space-x-4   flex justify-center items-center">
                  <button
                    className="font-normal text-2xl bg-blue-500 text-white p-2 rounded w-12  "
                    onClick={() => {
                      GuestSelectedAd(
                        "down",
                        "Child",
                        setGuestAdult,
                        setGuestChild
                      );
                    }}>
                    -
                  </button>
                  <input
                    className="bg-white w-12 text-center border font-medium text-xl rounded"
                    value={GuestChild}
                    id="Child"
                    onChange={(e) =>
                      GuestValueManual(e, setGuestAdult, setGuestChild)
                    }
                  />
                  <button
                    className="font-normal text-2xl bg-blue-500 text-white p-2 rounded w-12"
                    onClick={() => {
                      GuestSelectedAd(
                        "up",
                        "Child",
                        setGuestAdult,
                        setGuestChild
                      );
                    }}>
                    +
                  </button>
                </div>
              </div>
              <div className="col-span-2 flex justify-end lg:pr-7 mt-4 max-w-6xl">
                <button
                  id="btncheckavail"
                  className="font-normal text-xl bg-blue-500 text-white p-2 rounded "
                  onClick={() =>
                    onCheckAvail(
                      panTo,
                      loc,
                      setAvailability,
                      isLoaded,
                      boundaries,
                      hasSelectedLoc
                    )
                  }>
                  Check Availability
                </button>

                {/* <Link
                // to={{
                //   //pathname: `/${this.props.Location}/${this.props.Hname}/avail`,
                //   search: `?CIdate=${dateCI.format(
                //     "MM/DD/YYYY"
                //   )}&COdate=${dateCO.format(
                //     "MM/DD/YYYY"
                //   )}&GA=${GuestAdult}&GCH=${GuestChild}`,
                // }}
                >
                  <button
                    id="btncheckavail"
                    className="font-normal text-xl bg-blue-500 text-white p-2 rounded "
                  >
                    Check Availability
                  </button>
                </Link> */}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-4 ">
            <div className="col-span-full lg:col-span-9 space-y-4">
              {availaibity_ask &&
                (() => {
                  if (places_avail.nearby_places.length === 0) {
                    return (
                      <>
                        <div
                          className=" grid grid-rows-6 grid-flow-col lg:grid-rows-3
           grid-cols-12  pr-6 pl-6 pt-6 pb-6 space-x-4 shadow-3xl rounded-lg 
           gap-y-6 bg-white justify-center border border-black ">
                          <div className="col-span-full ">
                            <p className=" whitespace-nowrap  text-center ">
                              There seems to be not places in your search
                              criteria please try other locations
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  } else {
                    return places_avail.nearby_places.map((current, i) => {
                      return places_avail.hotelspecifics[i][0].map((c, j) => {
                        if (
                          //true
                          c.isavail === true
                          //&&
                          // moment(dateCO.toString("MM/DD/YYYY")).diff(
                          //   moment(dateCI.toString("MM/DD/YYYY")),
                          //   "days"
                          // ) >= c.minimumstay &&
                          // GuestAdult + GuestChild <= c.maxoccupancy
                        ) {
                          //&& c.minimumstay>=dateCO.diff(dateCI, "days")

                          return (
                            <div
                              className=" grid grid-rows-6 grid-flow-col lg:grid-rows-3
           grid-cols-12  pr-6 pl-6 pt-6 pb-6 space-x-4 shadow-3xl rounded-lg 
           gap-y-6 bg-white">
                              <div className="col-span-full row-span-6 lg:col-span-7 lg:row-span-3 xl:col-span-7 xl:row-span-6 ">
                                <RoomCarousel
                                  roomphoto={c.photourls}
                                  roomtype={c.roomtype}
                                />
                              </div>
                              <div
                                className="col-span-full row-span-6 lg:col-span-5 
                        lg:row-span-2 xl:col-span-4 xl:row-span-6">
                                <div className="grid grid-cols-full space-y-2">
                                  <div className="col-span-full">
                                    <p className="decoration-4 text-2xl">
                                      {`${c.roomtype} @ ${current.hotelwebsite}`}
                                    </p>
                                  </div>
                                  <div className="col-span-full">
                                    <p>{c.description}</p>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="col-span-full row-span-1 
                        lg:col-span-5 lg:row-auto xl:col-span-1 xl:row-span-6 ">
                                <div
                                  className={
                                    c.isavail === true
                                      ? "flex col-span-12  justify-center lg:justify-end xl:justify-center"
                                      : "hidden"
                                  }>
                                  <a
                                    href={
                                      process.env.NODE_ENV === "development"
                                        ? `http://localhost:3001/USA/${
                                            current.hotelname
                                          }/avail?CIdate=${dateCI.format(
                                            "MM/DD/YYYY"
                                          )}&COdate=${dateCO.format(
                                            "MM/DD/YYYY"
                                          )}&GA=${GuestAdult}&GCH=${GuestChild}`
                                        : `https://www.reserv4me.com/USA/${
                                            current.hotelname
                                          }/avail?CIdate=${dateCI.format(
                                            "MM/DD/YYYY"
                                          )}&COdate=${dateCO.format(
                                            "MM/DD/YYYY"
                                          )}&GA=${GuestAdult}&GCH=${GuestChild}`
                                    }>
                                    <button
                                      className={
                                        c.exeedsocup === false
                                          ? "font-normal  bg-blue-500 text-white p-2 rounded inline whitespace-nowrap"
                                          : "font-normal  bg-blue-400 text-white p-2 rounded inline whitespace-nowrap"
                                      }
                                      id={`button-${current.hotelname}`}
                                      disabled={
                                        c.exeedsocup === false ? false : true
                                      }>
                                      Reserve Now
                                    </button>
                                  </a>
                                </div>
                                <div
                                  className="col-span-full row-span-1 
                        lg:col-span-5 lg:row-auto xl:col-span-1 xl:row-span-6">
                                  <p
                                    className={
                                      c.isavail === true &&
                                      c.exeedsocup === false
                                        ? "flex col-span-12  justify-center lg:justify-end xl:justify-center"
                                        : "hidden"
                                    }>
                                    {`${c.totalcost} + ${
                                      c.tax_except === true
                                        ? "Fees"
                                        : "Tax & Fees"
                                    }`}
                                  </p>
                                  <p
                                    className={
                                      c.exeedsocup === true
                                        ? "flex col-span-12  justify-center lg:justify-end xl:justify-center"
                                        : "hidden"
                                    }>
                                    Exceeds Occupancy
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return <></>;
                        }
                      });
                    });
                  }
                })()}
            </div>

            <div
              className="col-span-full lg:col-span-3 border-black border-2 shadow-3xl rounded-lg bg-white p-2 m-auto"
              hidden={availaibity_ask === false ? true : false}>
              <MapContainer
                onMapLoad={onMapLoad}
                onMapClick={onMapClick}
                onBoundsChanged={onBoundsChanged}
                mapRef={mapRef}
                setBoundaries={setBoundaries}
                places={places_avail}
                availaibity_ask={availaibity_ask}
              />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Welcome_Home;
