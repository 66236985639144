import React, { Component } from "react";
import Hotelbanner from "../Hotelbanner";
import { withRouter } from "react-router-dom";
import ReservationMaking from "./ReservationMaking";
import Mainfooter from "../Footer/Mainfooter";

class Reservationpage extends Component {
  render() {
    //console.log("REservationPage", this.props.Hname);
    return (
      <>
        <div className="flex flex-col min-h-screen overflow-hidden">
          <Hotelbanner Hname={this.props.Hname} />
          <main className="grow">
            <div className="relative mt-20">
              <ReservationMaking />
            </div>
          </main>
          <Mainfooter />
        </div>
      </>
    );
  }
}

export default withRouter(Reservationpage);
