import React, { Component, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Hotelbanner from "../Hotelbanner";
import Mainfooter from "../Footer/Mainfooter";
import moment from "moment";
import CheckoutFormLink from "./checkoutFormLink";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
let stripePromise = null;
//console.log("🚀 ~ file: CheckoutFormSetupLater.js ~ line 28 ~ .then ~ result");
if (window.location.pathname === "/payment") {
  stripePromise = fetch(
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/v1/getHUID"
      : "https://apiv1.reserv4me.com/v1/getHUID",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payment_url: window.location.search
          .split("?")[1]
          .split("&")[0]
          .split("=")[1],
        reg_Num: window.location.search
          .split("?")[1]
          .split("&")[1]
          .split("=")[1],
      }),
    }
  )
    .then((data) => data.json())
    .catch((err) => {
      //console.log("undefinde", err);
    })
    .then((result) => {
      if (process.env.NODE_ENV === "development") {
        return loadStripe("pk_test_cAXwtQDWd9EnLQ1dYFDCety800ZWdqhQOz", {
          stripeAccount: result.connectedAccID,
        });
      } else {
        return loadStripe("pk_live_krEjGPuE0C0FA1kFgMsP5cJU00gym7y6of", {
          stripeAccount: result.connectedAccID,
        });
      }
    });
}

class payment_landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_url: "",
      reg_Num: 0,
      reservation_info: [],
      not_found: false,
      datagathered: false,
      loading: true,
      policiesunderstood: false,
      clientSecret: "",
      paymentsuccessful: null,
      isFound: true,
    };
    this.onCheckboxPolicyChanged = this.onCheckboxPolicyChanged.bind(this);
  }

  componentDidMount() {
    const { search } = this.props.location;
    this.setState(
      {
        payment_url: search.split("?")[1].split("&")[0].split("=")[1],
        reg_Num: search.split("?")[1].split("&")[1].split("=")[1],
      },
      () => {
        this.obtain_reservation_details();
      }
    );
  }
  onCheckboxPolicyChanged(e) {
    this.setState((prevState) => ({
      policiesunderstood: !prevState.policiesunderstood,
    }));
  }

  obtain_reservation_details = () => {
    fetch(
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/v1/searResPayment"
        : "https://apiv1.reserv4me.com/v1/searResPayment",
      {
        method: "post",
        headers: {
          "Access-Control-Allow-Origin":
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000"
              : "https://apiv1.reserv4me.com",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payment_url: this.state.payment_url,
          reg_Num: this.state.reg_Num,
        }),
      }
    )
      .catch((err) => {
        //console.log("undefinde", err);
      })
      .then((response) => response.json())
      .catch((err) => {
        //console.log("undefinde", err);
        this.setState((prevState) => ({
          isFound: !prevState.isFound,
          loading: !prevState.loading,
        }));
      })
      .then((data) => {
        if (
          data !== undefined &&
          data !== "NotFound" &&
          data !== "Unauthorized"
        ) {
          this.setState(
            (prevState) => ({
              reservation_info: data,
              datagathered: !prevState.datagathered,
              loading: !prevState.loading,
            }),
            () => {
              fetch(
                process.env.NODE_ENV === "development"
                  ? "http://localhost:3000/v1/paymentLink"
                  : "https://apiv1.reserv4me.com/v1/paymentLink",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "hi",
                  },
                  body: JSON.stringify({
                    tripid: this.state.payment_url,
                    RegNum: this.state.reg_Num,
                    AmountDue:
                      this.state.reservation_info.grand_total.downpayment,
                    hotel_web_id:
                      this.state.reservation_info.reservation_details
                        .hotel_web_id,
                  }),
                }
              )
                .then((res) => res.json())
                .then((data) => this.changeClient(data.clientSecret));
            }
          );
        } else {
          // console.log("undefinde", data);
          // this.setState((prevState) => ({
          //   isFound: !prevState.isFound,
          //   loading: !prevState.loading,
          // }));
        }
      });
  };

  stoploading = () => {
    this.setState((prevState) => ({ loading: !prevState.loading }));
  };

  changeClient = (newnum) => {
    this.setState((prevState) => ({ clientSecret: newnum }));
  };

  paymentStatusChange = () => {
    this.setState((prevState) => ({
      paymentsuccessful: !prevState.paymentStatusChange,
    }));
  };

  onSuccessfulPayment = (payment_ID) => {
    fetch(
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/v1/paymentLinkSuccessful"
        : "https://apiv1.reserv4me.com/v1/paymentLinkSuccessful",
      {
        method: "post",
        headers: {
          "Access-Control-Allow-Origin":
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000"
              : "https://apiv1.reserv4me.com",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payment_url: this.state.payment_url,
          reg_Num: this.state.reg_Num,
          reservation_info: this.state.reservation_info,
          payment_ID: payment_ID,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          this.setState((prevState) => {
            let reservation_info = Object.assign(
              {},
              prevState.reservation_info
            );
            reservation_info.amount_Due = data.amount_Due;
            reservation_info.payment_total = data.payment_total;
            return { reservation_info };
          });
        }
      });
  };

  render() {
    if (window.location.pathname === "/payment") {
      const appearance = {
        theme: "stripe",
        mode: "billing",
      };

      const options = {
        clientSecret: this.state.clientSecret,
        appearance,
      };
      const { reservation_info } = this.state;

      return (
        <>
          <div>
            {/*Loading screen*/}
            <div
              hidden={this.state.loading === false ? true : false}
              className=" fixed pt-20  inset-0 mx-auto z-10  bg-black/75  ">
              <div className="loading"></div>
              <div className="absolute  w-60 left-1/2 top-1/2  -translate-y-2/4 -translate-x-2/4 ">
                <p className="text-2xl text-white -translate-y-16">
                  Please be patient
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col min-h-screen overflow-hidden">
            <Hotelbanner Hname={this.props.Hname} />
            <main className="grow">
              {/*  Page illustration */}
              <div className="relative mt-20" aria-hidden="true">
                {/* <p>this is a payment {this.state.payment_url}</p>
              <p>
                Reg
                {this.state.reg_Num}
              </p> */}
                {(() => {
                  if (this.state.datagathered === true) {
                    return (
                      <div className="grid  grid-flow-col grid-cols-12 grid-rows-2 lg:grid-rows-1 p-6 mx-2 space-x-0 lg:space-x-4 ">
                        <div className=" grid shadow-3xl rounded-lg col-span-full row-span-full row-start-1 lg:col-span-6 lg:row-span-full space-y-4 my-4">
                          <div className="col-span-12 px-4 text-2xl font-light pt-4">
                            {`Congratulations!, one step closer from completing your reservation`}
                          </div>
                          <div className="grid grid-cols-subgrid col-span-12 p-6 mx-2 space-x-0">
                            <div className="col-span-12 px-4 text-xl font-light pt-4">
                              {`Reg:${reservation_info.reg_Num}`}
                            </div>
                            <div className="col-start-1 col-span-12 md:col-start-1 md:col-span-6 px-4  text-sm font-light pt-4 ">
                              <p>{`Name:${reservation_info.reservation_details.client_fname} ${reservation_info.reservation_details.client_lname}`}</p>
                            </div>
                            <div className="col-start-1 col-span-12 md:col-start-7 md:col-span-6 px-4  text-sm font-light pt-4 ">
                              <div className="grid grid-cols-12 grid-rows-1 space-x-0">
                                <div className="col-start-1 col-span-6    text-sm font-light  ">
                                  {`From:${moment(
                                    reservation_info.reservation_details.ci_date
                                  ).format("MM/DD/YY")}`}
                                </div>
                                <div className="col-start-7 col-span-6    text-sm font-light  ">
                                  {`Until:${moment(
                                    reservation_info.reservation_details.co_date
                                  ).format("MM/DD/YY")}`}
                                </div>
                              </div>
                            </div>
                            <div className="grid col-span-12 p-6 mx-2 space-x-0 lg:space-x-4">
                              <div className="col-start-1 col-span-4 text-sm font-light  ">
                                {`Room Type Code`}
                              </div>
                              <div className="col-start-5 col-span-4 text-sm font-light  ">
                                {`Guest Count`}
                              </div>
                              <div className="col-start-9 col-span-4 text-sm font-light  ">
                                {`Price (No Tax)`}
                              </div>
                              {reservation_info.room_information.map(
                                (current, i) => {
                                  return (
                                    <>
                                      <div className="col-start-1 col-span-4 text-sm font-light ">
                                        {`${current.unittype}`}
                                      </div>
                                      <div className="col-start-5 col-span-4 text-sm font-light ">
                                        {`${current.num_Adg}-${current.num_CHg}`}
                                      </div>
                                      <div className="col-start-9 col-span-4 text-sm font-light ">
                                        {`${current.total_price}`}
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </div>
                            <div className="grid col-span-12 grid-rows-6">
                              <div className="col-start-5 col-span-4 text-sm font-light  ">
                                {`Room(s) Price:`}
                              </div>
                              <div className="col-start-9 col-span-4 text-sm font-light ">
                                {`$${parseFloat(
                                  reservation_info.grand_total.total_wo_tax
                                ).toFixed(2)}`}
                              </div>
                              <div className="col-start-5 col-span-4 text-sm font-light  ">
                                {`Taxes & fees:`}
                              </div>
                              <div className="col-start-9 col-span-4 text-sm font-light ">
                                {`$${parseFloat(
                                  reservation_info.grand_total.tax_total
                                ).toFixed(2)}`}
                              </div>
                              <div className="col-start-5 col-span-4 text-sm font-light ">
                                {`Grand Total:`}
                              </div>
                              <div className="col-start-9 col-span-4 text-sm font-light ">
                                {`$${parseFloat(
                                  reservation_info.grand_total.total_w_tax
                                ).toFixed(2)}`}
                              </div>

                              <div className="col-start-5 col-span-4 text-sm font-semibold ">
                                {`Amount Payed:`}
                              </div>
                              <div className="col-start-9 col-span-4 text-sm  font-semibold ">
                                {`$${parseFloat(
                                  reservation_info.payment_total
                                ).toFixed(2)}`}
                              </div>
                              <div className="col-start-5 col-span-4 text-sm font-semibold ">
                                {`Total Due:`}
                              </div>
                              <div className="col-start-9 col-span-4 text-sm  font-semibold ">
                                {`$${parseFloat(
                                  reservation_info.amount_Due
                                ).toFixed(2)}`}
                              </div>

                              <div
                                className="col-start-5 col-span-4 text-sm font-semibold "
                                hidden={
                                  this.state.paymentsuccessful === true
                                    ? true
                                    : false
                                }>
                                {`Amount Due Today:`}
                              </div>
                              <div
                                className="col-start-9 col-span-4 text-sm  font-semibold "
                                hidden={
                                  this.state.paymentsuccessful === true
                                    ? true
                                    : false
                                }>
                                {`$${parseFloat(
                                  reservation_info.grand_total.downpayment
                                ).toFixed(2)}`}
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-span-12 p-6 mx-2 "
                            hidden={
                              this.state.paymentsuccessful === true
                                ? true
                                : false
                            }>
                            <div className="flex space-x-4 h-10 pb-4 ">
                              <img
                                alt="mc"
                                src="https://res.cloudinary.com/dykrzfpex/image/upload/v1581754834/mastercard-badge_ei9x5f.png"></img>
                              <img
                                alt="visa"
                                src="https://res.cloudinary.com/dykrzfpex/image/upload/v1581754836/visa-badge_fiwqwy.png"></img>
                              <img
                                alt="discover"
                                src="https://res.cloudinary.com/dykrzfpex/image/upload/v1581754834/discover-badge_pkcnsn.png"></img>
                              <img
                                alt="ae"
                                src="https://res.cloudinary.com/dykrzfpex/image/upload/v1581754834/ae-badge_a5kr0n.png"></img>
                            </div>
                            <div className="">
                              <div className="space-y-4 space-x-4">
                                <div>
                                  {stripePromise && this.state.clientSecret && (
                                    <Elements
                                      Elements
                                      options={options}
                                      stripe={stripePromise}>
                                      <ElementsConsumer>
                                        {({ stripe, elements }) => {
                                          return (
                                            <CheckoutFormLink
                                              loading={this.stoploading}
                                              RegNum={this.state.reg_Num}
                                              AmountDue={
                                                reservation_info.grand_total
                                                  .downpayment
                                              }
                                              tripid={
                                                reservation_info.payment_url
                                              }
                                              hotel_web_id={
                                                reservation_info
                                                  .reservation_details
                                                  .hotel_web_id
                                              }
                                              onCheckboxPolicyChanged={
                                                this.onCheckboxPolicyChanged
                                              }
                                              policiesunderstood={
                                                this.state.policiesunderstood
                                              }
                                              clientSecret={
                                                this.state.clientSecret
                                              }
                                              paymentStatusChange={
                                                this.paymentStatusChange
                                              }
                                              paymentsuccessful={
                                                this.state.paymentsuccessful
                                              }
                                              clientInfo={{
                                                client_fname:
                                                  reservation_info
                                                    .reservation_details
                                                    .client_fname,
                                                client_lname:
                                                  reservation_info
                                                    .reservation_details
                                                    .client_lname,
                                                client_email:
                                                  reservation_info
                                                    .reservation_details
                                                    .client_email,
                                              }}
                                              onSuccessfulPayment={
                                                this.onSuccessfulPayment
                                              }
                                            />
                                          );
                                        }}
                                      </ElementsConsumer>
                                    </Elements>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-span-12 p-6 mx-auto "
                            hidden={
                              this.state.paymentsuccessful === true
                                ? false
                                : true
                            }>
                            <h3 className="text-xl font-light  ">
                              Payment was Successful,an Email is on its way
                            </h3>
                          </div>
                        </div>
                        <div className=" col-span-full shadow-3xl rounded-lg row-span-full row-start-3 lg:col-span-6 lg:row-span-full pb-6 lg:pb-0 space-y-4 my-4">
                          <h2 className="p-4 text-xl">Policies:</h2>

                          <div class="accordion-body py-6 px-5">
                            <ul>
                              <li className="space-x-4">
                                <h3>Check-in/Check-out info:</h3>
                                <span className="pl-2 block">
                                  Check-in:{" "}
                                  {this.state.datagathered
                                    ? reservation_info.policies.timecheckin
                                    : ""}{" "}
                                  local time
                                  <br />
                                  Check-out:{" "}
                                  {this.state.datagathered
                                    ? reservation_info.policies.timecheckout
                                    : ""}{" "}
                                  local time
                                </span>
                              </li>

                              <li className="space-x-4">
                                <h3>Cancelation Policy:</h3>
                                <span className="pl-2 block">
                                  You can cancel free of charge up to{" "}
                                  {this.state.datagathered
                                    ? `${reservation_info.policies.freetime} ${reservation_info.policies.timeframe} `
                                    : ""}
                                  prior to your Check-in date outside the free
                                  cancellation window a fee of{" "}
                                  {this.state.datagathered
                                    ? `${reservation_info.policies.cost} 
                                                     ${
                                                       reservation_info.policies
                                                         .typeofcancel ===
                                                       "percentage"
                                                         ? "%"
                                                         : reservation_info
                                                             .policies
                                                             .typeofcancel ===
                                                           "night"
                                                         ? " night + tax and fees "
                                                         : ""
                                                     } `
                                    : ""}{" "}
                                  will be charged
                                </span>
                              </li>
                              <li className="space-x-4">
                                <h3>Parking Policy:</h3>
                                <span className="pl-2 block">
                                  {this.state.datagathered
                                    ? `${reservation_info.policies.parking_policy}  `
                                    : ""}
                                </span>
                              </li>
                              <li className="space-x-4">
                                <h3>Pet Policy:</h3>
                                <span className="pl-2 block">
                                  {this.state.datagathered
                                    ? `${reservation_info.policies.pet_policy}  `
                                    : ""}
                                </span>
                              </li>
                              <li className="space-x-4">
                                <h3>Additinal Policies:</h3>
                                <span className="pl-2 block">
                                  {this.state.datagathered
                                    ? `${reservation_info.policies.additional_pol}  `
                                    : ""}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    if (this.state.isFound === false) {
                      return (
                        <>
                          <p className=" absolute  left-1/2 top-1/2  -translate-y-2/4 -translate-x-2/4  ">
                            Hmmmm.... It looks like something went wrong please
                            reach out to your rental property. Thank you
                          </p>
                        </>
                      );
                    } else {
                      return (
                        <>
                          {/*Loading screen*/}
                          <div
                            hidden={this.state.loading === false ? true : false}
                            className=" fixed pt-20  inset-0 mx-auto z-10  bg-black/75  ">
                            <div className="loading"></div>
                            <div className="absolute  w-60 left-1/2 top-1/2  -translate-y-2/4 -translate-x-2/4 ">
                              <p className="text-2xl text-white -translate-y-16">
                                Please be patient
                              </p>
                            </div>
                          </div>
                        </>
                      );
                    }
                  }
                })()}
              </div>
            </main>
            <Mainfooter />
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }
}

export default withRouter(payment_landing);
